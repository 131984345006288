<template>
  <v-dialog v-model="dialog" persistent width="560">
    <v-card>
      <v-card-title>Transfer this Item</v-card-title>
      <v-card-text>
        <v-text-field
          readonly
          label="Item Name"
          :value="item.item_name"
        ></v-text-field>
        <div class="d-flex">
          <v-text-field
            label="Quantity"
            v-model="form.quantity"
            prepend-icon="mdi-box"
          ></v-text-field>
          <v-text-field
            readonly
            label="Unit Price"
            v-model="item.unit_price"
            prepend-icon="mdi-box"
          ></v-text-field>
          <v-text-field
            readonly
            label="Total Amount"
            v-model="total_amount"
            prepend-icon="mdi-box"
          ></v-text-field>
        </div>
        <p>Destination"</p>
        <div class="d-flex">
          <v-select
            prepend-icon="mdi-forward"
            class="mr-2"
            label="From"
            :items="this.branches"
            item-text="branch_name"
            v-model="form.from_branch"
            @change="insert_branch_id('from')"
          ></v-select>
          <v-select
            prepend-icon="mdi-forward"
            label="To"
            :items="this.branches"
            item-text="branch_name"
            v-model="form.to_branch"
            @change="insert_branch_id('to')"
          ></v-select>
        </div>
        <div class="d-flex">
          <p class="mt-4 mr-4">Transfer Date:</p>
          <input
            type="date"
            v-model="form.transfer_date"
            label="Transfer Date"
          />
        </div>
        <v-textarea
          label="Notes"
          prepend-icon="mdi-pen"
          v-model="form.notes"
        ></v-textarea>
        <v-text-field
          readonly
          prepend-icon="mdi-account-circle"
          label="Processed by"
          :value="full_name"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="submit" :loading="loading">Submit</v-btn>
        <v-btn @click="$emit('close')"> Close</v-btn>
        <v-spacer></v-spacer>
        <p class="caption grey--text">itemTransferModal2.vue</p>
      </v-card-actions>
    </v-card>

    <!-- qyuestions -->
    <v-dialog width="450" v-model="show_question" persistent>
      <v-card>
        <v-card-title>
          <span style="text-align: center">
            Do you want to merge or add new item?</span
          >
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-btn color="primary" class="mr-2" @click="merge">Merge</v-btn>
          <add-item-modal
            :item_transfer="true"
            :quantity="form.quantity"
            :item="form"
          />
          <v-btn @click="show_question = false">Cancel</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Merge Dialog -->
    <merge-item-modal
      :item="form"
      v-if="show_merge_dialog"
      @close="show_merge_dialog = false"
    />
  </v-dialog>
</template>

<script>
import moment from "moment";

import { mapGetters } from "vuex";
import AddItemModal from "./addItemModal.vue";
import { mapActions } from "vuex";

export default {
  components: {
    mergeItemModal: () => import("./mergeItemModal.vue"),
    AddItemModal,
  },
  props: ["item"],
  data() {
    return {
      item_transfer: true,
      show_question: false,
      show_merge_dialog: false,
      dialog: true,
      loading: false,
      form: {
        item_id: "",
        item_name: "",
        from_branch: "",
        to_branch: "",
        quantity: "",
        transfer_date: "",
        notes: "",
        from_branch_id: "",
        to_branch_id: "",
        amount: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      branches: "branch/branches",
    }),
    full_name() {
      return this.user.full_name;
    },
    total_amount() {
      return this.item.unit_price * this.form.quantity;
    },
  },
  created() {
    // set default values
    this.form.item_id = this.item.id;
    this.form.quantity = this.item.quantity;
    this.form.item_name = this.item.item_name;
    this.form.from_branch = this.user.branch.branch_name;
    this.form.transfer_date = moment().format("Y-MM-DD");

    // set items for add
    this.set_item_order_for_add(this.item);
    // set from branch id
    this.insert_branch_id("from");
  },
  methods: {
    ...mapActions({
      set_item_order_for_add: "item/set_item_order_for_add",
    }),
    insert_branch_id(type) {
      // Determine the correct branch and branch_id fields based on the type
      let branchNameField =
        type === "to" ? this.form.to_branch : this.form.from_branch;
      let branchIdField = type === "to" ? "to_branch_id" : "from_branch_id";

      // Find the matching branch
      let branch = this.branches.find(
        (b) => b.branch_name.toLowerCase() === branchNameField.toLowerCase()
      );

      // Check if branch exists and update the corresponding field
      if (branch) {
        this.form[branchIdField] = branch.id;
      } else {
        console.error("Branch not found for:", branchNameField);
      }
      // set total amount
      this.form.amount = this.total_amount;
      console.log(this.form);
    },
    merge() {
      this.show_merge_dialog = true;
      this.show_question = false;
      console.log(this.form);
    },
    submit() {
      // check for blanks
      if (this.form.notes == "" || this.form.to_branch == "")
        return alert("Please check all fields");
      this.show_question = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
